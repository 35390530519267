var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tableReady)?_c('cargill-bc-grid',{ref:"gridComponent",style:("height: 650px; width: 100%"),attrs:{"gridOptions":_vm.gridOptions,"columnDefs":_vm.columns,"getItems":_vm.getItems,"countItems":_vm.getDataCount,"detailCellRendererFramework":_vm.frameworkCellRenderer,"layer":"3","detailCellRendererParams":{
    mainKeys: _vm.mainKeys,
    i18nTable: _vm.i18nTable,
    mainKeysLevel: _vm.mainKeysLevel,
    parentLevelKey: {},
    keyLevel: this.level,
    metaKey: 'master',
    meta: _vm.meta,
    layer: 3,
    parentReloadTable: _vm.onChildChanged
  },"masterDetail":true,"selectable":false,"pageSize":13,"mode":_vm.gridMode,"customSetSize":_vm.customSetSize},on:{"grid-ready":_vm.onGridReady,"rowGroupOpened":_vm.onGroupOpened,"afterDataLoaded":_vm.afterDataLoaded}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }