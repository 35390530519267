<template>
  <cargill-bc-grid
    ref="gridComponent"
    :gridOptions="gridOptions"
    :columnDefs="columns"
    :getItems="getItems"
    :countItems="getDataCount"
    :style="`height: 650px; width: 100%`"
    :detailCellRendererFramework="frameworkCellRenderer"
    layer="3"
    :detailCellRendererParams="{
      mainKeys,
      i18nTable,
      mainKeysLevel,
      parentLevelKey: {},
      keyLevel: this.level,
      metaKey: 'master',
      meta,
      layer: 3,
      parentReloadTable: onChildChanged
    }"
    :masterDetail="true"
    :selectable="false"
    :pageSize="13"
    :mode="gridMode"
    :customSetSize="customSetSize"
    @grid-ready="onGridReady"
    @rowGroupOpened="onGroupOpened"
    @afterDataLoaded="afterDataLoaded"
    v-if="tableReady"
  />
</template>
<script>
import { createCrudColumns } from '@brain/core'
import RadioCellRenderer from './RadioCellRenderer'
import CargillBcGrid from './CargillBcGrid'
import FrameworkCellRenderer from './FrameworkCellRenderer'
import StuffingPortalActionsCellRenderer from './StuffingPortalActionsCellRenderer'
import StuffingPortalStatusCellRenderer from './StuffingPortalStatusCellRenderer'
import service from '../../api/stuffingPortalService'
import { metadataHelper, defaultGridOptions, gridUtils } from '@cargill/shared'
import Vue from 'vue'

export default {
  name: 'StuffingPortalTable',
  components: {
    CargillBcGrid
  },
  props: {
    endpoint: {
      type: String,
      default: ''
    },
    mainKeys: [],
    mainKeysLevel: [],
    i18nTable: null,
    tabIndex: null,
    gridMode: {
      type: String,
      default: 'serverSide'
    }
  },
  data() {
    return {
      gridOptions: {
        ...defaultGridOptions,
        ...gridUtils.defaultGridOptions,
        getRowHeight: this.getRowHeight,
        rowModelType: this.gridMode
      },
      originalMeta: {},
      meta: {},
      menu: false,
      columns: [],
      tableReady: false,
      frameworkCellRenderer: null,
      keyLevel: null,
      level: 0,
      childNodeExpanded: [],
      isChildChanged: false,
      currentPage: null
    }
  },
  beforeMount() {
    this.frameworkCellRenderer = Vue.extend(FrameworkCellRenderer)
  },
  async mounted() {
    const meta = await service.getMetaData(this.endpoint)

    this.level = metadataHelper.defineLevel(
      meta.data['master'],
      this.mainKeysLevel
    )
    this.childLevel = metadataHelper.defineLevel(
      meta.data['subMaster'],
      this.mainKeysLevel
    )
    this.originalMeta = meta
    await this.loadMetadata()
  },
  methods: {
    customSetSize(aggridOptions) {
      gridUtils.resize(aggridOptions)
    },
    async loadMetadata() {
      const meta = this.originalMeta
      this.meta = await this.processMetadata(meta.data)

      this.context = {
        parent: 'id',
        endpoint: this.mainKeysLevel[this.level].endpoint,
        childEndpoint: this.mainKeysLevel[this.childLevel]?.endpoint,
        masterName: this.mainKeysLevel[this.childLevel]?.masterName,
        childMeta: meta.data['subMaster'],
        meta: meta.data['master'],
        i18nTable: this.i18nTable
      }

      this.columns = this.createColumns(meta.data['master'])
      this.tableReady = true
    },
    translate(key) {
      return this.$t(`controlTower.pages.${this.i18nTable}.${key}`)
    },
    createColumns(metadata) {
      const crudColumns = createCrudColumns(
        metadata,
        this.gridMode,
        this.translate
      ).filter((x) => x.key != 'id' && x.key != 'details')
      metadataHelper.defineCellRendererStuffingPortal(
        crudColumns,
        RadioCellRenderer,
        StuffingPortalActionsCellRenderer,
        StuffingPortalStatusCellRenderer
      )
      return crudColumns
    },
    async processMetadata(metadata) {
      const _service = service.createModalCrudService(
        this.mainKeysLevel[this.level].endpoint
      )
      const _childService = service.createModalCrudService(
        this.mainKeysLevel[this.childLevel]?.endpoint,
        this.mainKeysLevel[this.childLevel]?.masterName
      )
      const promisses = [this.builMetadata(metadata['master'], _service)]
      if (_childService != null) {
        promisses.push(this.builMetadata(metadata['subMaster'], _childService))
      }
      await Promise.all(promisses)
      return metadata
    },
    async builMetadata(metadata, service) {
      await metadataHelper.buildMetadata(metadata, service)
    },
    onGroupOpened(event) {
      this.keyLevel = event.data[this.level]
      if (event.expanded) {
        this.childNodeExpanded.push(event.data.id)
      } else {
        this.childNodeExpanded = this.childNodeExpanded.filter(
          (x) => x != event.data.id
        )
      }
    },
    getRowHeight(params) {
      var isDetailRow = params.node.detail

      if (!isDetailRow) {
        return undefined
      }

      return 300
    },
    getDataCount(filter) {
      const { endpoint } = this.mainKeysLevel[this.level]
      return service.getItemsCount(filter, endpoint, null)
    },
    getItems(params) {
      const { endpoint } = this.mainKeysLevel[this.level]
      this.$emit('onFilterApplied', {
        grid: params
      })
      return service.getItems(params, endpoint, null)
    },
    reloadTable(useCurrentPage = false) {
      const bcGridRef = this.$refs.gridComponent
      if (useCurrentPage) {
        this.currentPage = bcGridRef.aggridApi.paginationGetCurrentPage()
      }
      bcGridRef?.loadData()
    },
    loadTable() {
      this.childNodeExpanded = []
      this.isChildChanged = false
      this.reloadTable()
    },
    afterDataLoaded() {
      const bcGridRef = this.$refs.gridComponent
      let currentPageAfterLoaded =
        bcGridRef.aggridApi.paginationGetCurrentPage()
      if (
        this.currentPage != null &&
        currentPageAfterLoaded !== this.currentPage
      ) {
        bcGridRef.aggridApi.paginationGoToPage(this.currentPage)
        this.currentPage = null
        return
      }
      if (this.isChildChanged) {
        bcGridRef.aggridApi.forEachNode((node) => {
          if (
            node.data != null &&
            this.childNodeExpanded.includes(node.data.id)
          ) {
            node.setExpanded(true)
            this.childNodeExpanded = this.childNodeExpanded.filter(
              (x) => x != node.data.id
            )
          }
        })
        this.isChildChanged = false
      }
    },
    onChildChanged() {
      const bcGridRef = this.$refs.gridComponent
      this.isChildChanged = true
      this.currentPage = bcGridRef.aggridApi.paginationGetCurrentPage()
      this.reloadTable()
    },
    onGridReady(params) {
      this.context.reloadTable = this.reloadTable
      params.api.context = Object.assign(params.api.context, this.context)
    }
  },
  provide() {
    return {
      validationResult: {}
    }
  }
}
</script>
<style lang="scss" scoped></style>
