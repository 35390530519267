import {
  dashboardUtils,
  api,
  createCrudService,
  handleError
} from '@cargill/shared'

const baseURL = '/api/controltower/stuffingTerminal'

const service = {}

service.getReserves = async (_baseFilter) => {
  const url = `${baseURL}/getReserves`
  return await dashboardUtils.filterDashboard(_baseFilter, url)
}

service.getContainersWithdrawal = async (_baseFilter) => {
  const url = `${baseURL}/getContainersWithdrawal`
  return await dashboardUtils.filterDashboard(_baseFilter, url)
}

service.getUnload = async (_baseFilter) => {
  const url = `${baseURL}/getUnload`
  return await dashboardUtils.filterDashboard(_baseFilter, url)
}

service.getBoarding = async (_baseFilter) => {
  const url = `${baseURL}/getBoarding`
  return await dashboardUtils.filterDashboard(_baseFilter, url)
}

service.getDocumentation = async (_baseFilter) => {
  const url = `${baseURL}/getDocumentation`
  return await dashboardUtils.filterDashboard(_baseFilter, url)
}

service.getFobControl = async (_baseFilter) => {
  const url = `${baseURL}/getFobControl`
  return await dashboardUtils.filterDashboard(_baseFilter, url)
}

const baseControlTowerUrl = '/api/controltower'

service.getMetaData = async (endpoint) => {
  try {
    const url = `${baseControlTowerUrl}/${endpoint}Metadata`
    var response = await api.get(url)
    return response
  } catch (err) {
    console.error(`Failed to retrieve data from ${endpoint}Metadata `, err)
  }
}

service.getItemsCount = async (_baseFilter, endpoint, keyLevel) => {
  try {
    const url = `${baseControlTowerUrl}/${endpoint}Count?${_baseFilter}`
    var response = await api.get(url)
    return response.data
  } catch (err) {
    console.error(`Failed to get ${endpoint}Count`, err)
  }
}

service.getItems = async (_baseFilter, endpoint, keyLevel) => {
  try {
    const url = `${baseControlTowerUrl}/${endpoint}?${_baseFilter}`
    var response = await api.get(url)
    return response.data
  } catch (err) {
    console.error(`Failed to get ${endpoint}`, err)
  }
}

service.getItemsChildCount = async (_baseFilter, endpoint, keyLevel) => {
  try {
    const url = `${baseControlTowerUrl}/${endpoint}Count?${_baseFilter}`
    var response = await api.get(url, {
      params: keyLevel
    })
    return response.data
  } catch (err) {
    console.error(`Failed to get ${endpoint}Count`, err)
  }
}

service.getItemsChild = async (_baseFilter, endpoint, keyLevel) => {
  try {
    const url = `${baseControlTowerUrl}/${endpoint}?${_baseFilter}`
    var response = await api.get(url, {
      params: keyLevel
    })
    return response.data
  } catch (err) {
    console.error(`Failed to get ${endpoint}`, err)
  }
}

service.createModalCrudService = (endpoint, masterName) => {
  if (endpoint == null) {
    return null
  }
  const url = `/api/controltower/${endpoint.split('/')[0]}`
  const service = createCrudService(url, api, { masterName: masterName })

  return service
}

const getResponseFilename = (response) => {
  const headerPart = response.headers['content-disposition'].split("''")

  const filename =
    Array.isArray(headerPart) && headerPart.length > 1 ? headerPart[1] : null

  return decodeURIComponent(filename)
}

service.createService = (name) => {
  const url = `/api/controltower/${name}`
  const service = createCrudService(url, api, {
    importLogUrl: '/api/controltower/importLog'
  })
  service.export = async (queryParams) => {
    try {
      queryParams = service.applyTabNameQuery(queryParams)

      const endPoint = queryParams
        ? `${url}/excelCustom?${queryParams}`
        : `${url}/excelCustom`
      const response = await api.get(endPoint, {
        responseType: 'blob'
      })

      const filename = getResponseFilename(response)

      return { data: response.data, filename }
    } catch (e) {
      handleError(e)
      throw e
    }
  }

  return service
}

service.CreateItem = async (endpoint, masterName, item, options) => {
  const url = `/api/controltower/${endpoint.split('/')[0]}`
  const service = createCrudService(url, api, {
    masterName: masterName
  })

  await service.create(item, options)
}

service.getExcelContainerTransfer = async (tabName) => {
  try {
    const baseUrl =
      '/api/controltower/logisticPortalExportControl/getExcelContainerTransfer'
    const url = tabName ? `${baseUrl}?tabName=${tabName}` : baseUrl
    const response = await api.get(url, {
      responseType: 'blob'
    })

    return { data: response.data }
  } catch (e) {
    handleError(e)
    throw e
  }
}

service.exportResult = async (id) => {
  const url = `/api/controltower/importLog/${id}/excel`
  try {
    const response = await api.get(url, {
      responseType: 'blob'
    })
    return { data: response.data }
  } catch (e) {
    console.log('ERROR', e)
  }
}

service.importContainerTransfer = async (formData, importTabs) => {
  const dumb = createCrudService('', '', {})
  importTabs = dumb.applyTabNameQuery(dumb.fixPlusSign(importTabs))
  importTabs = dumb.applyFixedColumnsFilter(importTabs)

  const endpoint = `/api/controltower/logisticPortalExportControl/importContainerTransfer`
  try {
    const response = await api.post(`${endpoint}?${importTabs}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    return response.data
  } catch (e) {
    dumb.handleError(e)
    return [
      {
        service: null,
        totalRows: 0,
        insertedRows: 0,
        updatedRows: 0,
        errorRows: 0,
        invalidFile: true
      }
    ]
  }
}
export default service
