<template>
  <bc-popover v-model="menu" placement="bottom-end">
    <template v-slot:activator="{ on, attrs }">
      <bc-btn
        icon
        class="filter-icon"
        v-bind="attrs"
        v-on="on"
        :max-width="30"
        :max-height="30"
        :title="$t('controlTower.pages.stuffingPortal.comments')"
      >
        <bc-icon>fas fa-comment-alt-lines</bc-icon>
      </bc-btn>
    </template>
    <template v-slot:header>
      {{ $t('controlTower.pages.stuffingPortal.comments') }}</template
    >

    <div id="global-filter-content">
      <v-timeline dense class="timeline-items">
        <v-slide-x-reverse-transition group hide-on-leave>
          <v-timeline-item
            v-for="comment in comments"
            :key="comment.id"
            :color="'#24BBEF'"
            small
            fill-dot
          >
            <v-alert
              :value="true"
              :color="'#262C39'"
              dark
              class="alert-container"
            >
              <div class="message-container">
                <div>
                  {{ formatDatetime(comment.date) }} -
                  <b>{{ comment.userName }}</b>
                </div>
                <div class="message-container__message">
                  {{ comment.message }}
                </div>
              </div>
            </v-alert>
          </v-timeline-item>
        </v-slide-x-reverse-transition>
      </v-timeline>

      <bc-card-actions class="card-actions-container">
        <bc-text-field
          filled
          v-model="comment.message"
          :label="label"
          class="input-spacing"
        ></bc-text-field>
        <bc-btn @click="sendComment" color="primary" id="btn-send-comment">
          <bc-icon>fas fa-paper-plane</bc-icon>
        </bc-btn>
      </bc-card-actions>
    </div>
  </bc-popover>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    comments: {
      type: Array,
      default: () => []
    },
    service: {},
    createId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      menu: null,
      inputModel: '',
      label: this.$t('controlTower.pages.stuffingPortal.comments'),
      comment: {
        message: ''
      }
    }
  },
  mounted() {},
  methods: {
    clearComment() {
      this.comment.message = ''
    },
    formatDatetime(date) {
      return moment(date).format(this.$t('application.dateFormat'))
    },
    async sendComment() {
      if (this.comment.message != '') {
        try {
          const response = await this.service.create(this.comment, {
            masterId: this.createId
          })
          if (response.id != undefined) {
            const comment = await this.service.getById(response.id)
            this.comments.unshift(comment)
          } else throw new Error()

          this.notify.success({
            title: this.$t(
              'controlTower.pages.stuffingPortal.commentSendSuccess'
            )
          })
        } catch (error) {
          this.clearComment()
          this.notify.error({
            title: this.$t('controlTower.pages.stuffingPortal.commentSendError')
          })
        }
      } else {
        this.notify.error({
          title: this.$t('controlTower.pages.stuffingPortal.emptyCommentError')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-actions-container {
  gap: 10px;
}
#btn-send-comment {
  padding: 0 !important; //overrides v-btn 0 40px!
  min-width: 60px;
}
.message-container {
  color: #959db5;
  text-transform: uppercase;

  &__message {
    overflow-wrap: break-word;
    inline-size: 300px;
  }
}
.alert-container {
  max-width: 350px;
}

.timeline-items {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 20px;
}
/* start scrollbar */

::-webkit-scrollbar {
  width: 3px;
  height: 85px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #b9b9b9;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #27bee7;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #27bee7;
}
/* end scrollbar*/
</style>
