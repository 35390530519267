<template>
  <div class="inline-radio">
    <bc-radio-group
      v-model="radioModel"
      row
      @change="update"
      :disabled="disabledPermission"
    >
      <bc-radio
        :label="$t('application.general.true')"
        :value="true"
      ></bc-radio>
      <bc-radio
        :label="$t('application.general.false')"
        :value="false"
      ></bc-radio>
    </bc-radio-group>
  </div>
</template>

<script>
import service from '../../api/stuffingPortalService'
import { eventHub } from '@cargill/shared'

export default {
  data() {
    return {
      radioModel: null,
      data: null,
      disabledPermission: false
    }
  },
  mounted() {
    this.radioModel = this.params.value
    this.data = this.params.data
    this.colId = this.params.column.colId
    this.endpoint = this.params.api.context.endpoint
    this.disabledPermission = !this.params.api.context.meta.fields.find(
      (field) => field.id == this.colId
    ).editable

    if (this.endpoint != undefined && this.endpoint != null) {
      this.service = service.createModalCrudService(this.endpoint, null)
    }
  },
  methods: {
    async update(value) {
      const dataAux = Object.assign({}, this.data)
      dataAux[this.colId] = value

      const response = await this.service.update(dataAux)

      if (response?.errorItems) {
        this.notify.error({
          title: this.$t('error.saveUpdateError')
        })
        this.radioModel = this.params.value
      } else {
        this.data[this.colId] = value
        eventHub.$emit('update-radio', { colId: this.colId, value: value })
        this.reloadTable()
        this.notify.success({
          title: this.$t('core.crud.updateSuccess')
        })
      }
    },
    reloadTable() {
      const { reloadTable } = this.params.api.context
      if (reloadTable != undefined) {
        reloadTable(true)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.inline-radio {
  display: flex;
}
::v-deep {
  .v-input--selection-controls {
    margin-top: 5px;
  }

  .v-input--radio-group--row .v-input--radio-group__input {
    flex-wrap: inherit;
  }
}
</style>
