var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"renderer-wrapper"},[_c('bc-grid',{ref:"gridComponent",style:("height: 100%; width: 100%"),attrs:{"gridOptions":Object.assign({}, {pagination: false,
      getRowHeight: _vm.getRowHeight},
      _vm.defaultGridOptions),"columnDefs":_vm.columnDefsDetail,"getItems":_vm.getItems,"countItems":_vm.getDataCount,"detailCellRendererFramework":_vm.frameworkCellRenderer,"detailCellRendererParams":{
      mainKeys: _vm.mainKeys,
      i18nTable: _vm.i18nTable,
      mainKeysLevel: _vm.mainKeysLevel,
      parentLevelKey: _vm.parentLevelKey,
      keyLevel: this.currentLevel,
      metaKey: _vm.metaKey,
      meta: _vm.meta,
      layer: _vm.layer - 1,
      parentReloadTable: this.parentReloadTable
    },"masterDetail":true,"selectable":false,"pageSize":13,"layer":_vm.layer - 1,"customSetSize":_vm.customSetSize,"mode":"serverSide"},on:{"grid-ready":_vm.onGridReady,"rowGroupOpened":_vm.onGroupOpened,"reload-table":_vm.reloadTable}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }