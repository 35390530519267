<template>
  <div class="table-container_filter cell">
    <!-- ADD -->
    <div
      v-if="
        hasAction('adddriver') ||
        hasAction('addbatch') ||
        hasAction('addwithdrawalcontainer') ||
        hasAction('adddocumentationbatch')
      "
    >
      <modal-crud-form
        v-if="formReady"
        :name="add.translateName(GetAction('add'))"
        :fields="add.formFields"
        :service="add.service"
        :translate="add.translate"
        :validationSchema="add.validationSchema"
        mode="create"
        label="what label"
        :details="[]"
        :serviceOptions="masterId"
        :width="width"
        :btnIcon="GetIcon('add')"
        @reloadTable="reloadTable"
        :permission="permission"
      ></modal-crud-form>
    </div>
    <!-- END ADD -->

    <!-- UPDATE -->
    <div v-if="hasAction('update')">
      <modal-crud-form
        v-if="formReady"
        :name="update.translateName(GetAction('update'))"
        :fields="update.formFields"
        :service="update.service"
        :translate="update.translate"
        :validationSchema="update.validationSchema"
        mode="update"
        label="what label"
        :details="[]"
        :serviceOptions="masterId"
        :width="width"
        :btnIcon="GetIcon('update')"
        @reloadTable="reloadTable"
        :itemId="this.rowId"
        :permission="permission"
      ></modal-crud-form>
    </div>
    <!-- END UPDATE -->

    <!-- DELETE ENTITY -->
    <div v-if="hasAction('delete')">
      <bc-btn
        icon
        class="filter-icon"
        :max-width="30"
        :max-height="30"
        @click="deleteEntity"
        :title="$t('application.actions.delete')"
        :disabled="!permission"
      >
        <bc-icon>fas fa-trash</bc-icon>
      </bc-btn>
    </div>
    <!-- END DELETE ENTITY-->

    <!-- COMMENTS -->
    <div v-if="hasAction('comments')">
      <comment-pop-over
        :comments="comment.comments"
        :service="comment.service"
        :createId="rowId"
      ></comment-pop-over>
    </div>
    <!-- END COMMENTS -->
  </div>
</template>

<script>
import { eventHub, ModalCrudForm, createMetaDataFields } from '@cargill/shared'
import CommentPopOver from './CommentPopOver.vue'
import service from '../../api/stuffingPortalService'

export default {
  components: {
    ModalCrudForm,
    CommentPopOver
  },
  data() {
    return {
      label: this.$t('controlTower.pages.stuffingPortal.comments'),
      actions: [],
      comments: [],
      context: {
        endpoint: '',
        parent: ''
      },
      endpoint: '',
      meta: {
        fields: [],
        id: ''
      },
      width: '55rem',
      i18nTable: '',
      formFields: [],
      service: null,
      translate: null,
      validationSchema: {},
      formReady: false,
      masterId: null,
      update: {},
      add: {},
      comment: {},
      rowId: null,
      permission: false
    }
  },
  beforeMount() {
    this.actions = this.params.node.data.actions
    this.rowId = this.params.node.data.id
    this.definePermission(this.params.api.context.parentPermission)

    if (this.hasAction('comments')) {
      const { meta } = this.params.api.context
      const masterName = this.capitalizeFirstLetter(meta.id),
        commentEndpoint = `${masterName}Comment`

      this.comment = {
        comments: this.getComments(this.params.node.data),
        service: service.createModalCrudService(
          commentEndpoint,
          masterName + 'Id'
        )
      }
    }

    if (
      this.hasAction('adddriver') ||
      this.hasAction('addbatch') ||
      this.hasAction('addwithdrawalcontainer') ||
      this.hasAction('adddocumentationbatch')
    ) {
      const { childMeta, i18nTable, masterName, childEndpoint, parent } =
        this.params.api.context
      const childMetaAux = this.metaUnwantedFieldsHandler(childMeta)
      this.add = createMetaDataFields(
        childMetaAux,
        `controlTower.pages.${i18nTable}`,
        service.createModalCrudService(childEndpoint, masterName)
      )

      this.add.translateName = (key) =>
        this.$t(`controlTower.pages.${i18nTable}.${childMetaAux.id}.${key}`)

      this.masterId = this.params.data[parent]
    }

    if (this.hasAction('update')) {
      const { meta, i18nTable, endpoint } = this.params.api.context
      const metaAux = this.metaUnwantedFieldsHandler(meta)

      this.update = createMetaDataFields(
        metaAux,
        `controlTower.pages.${i18nTable}`,
        service.createModalCrudService(endpoint, null)
      )

      this.update.translateName = (key) =>
        this.$t(`controlTower.pages.${i18nTable}.${metaAux.id}.${key}`)
    }

    this.formReady = true
  },
  mounted() {},
  computed: {},
  methods: {
    sendNotification(_notification) {
      /* @params
         type: notification type, success, error, warning
         message: notification message
      */
      eventHub.$emit('add-notification', _notification)
    },
    isBoolean(_val) {
      return typeof _val == 'boolean'
    },
    hasAddAction() {
      return this.actions.some((x) => x.includes('add'))
    },
    hasAction(_action) {
      return this.actions.indexOf(_action) > -1
    },
    GetAction(action) {
      return this.actions.find((x) => x.includes(action))
    },
    GetIcon(action_name) {
      const action = this.GetAction(action_name)

      const icons = {
        adddriver: 'fa-truck-container',
        addbatch: 'fa-boxes',
        addwithdrawalcontainer: 'fa-container-storage',
        adddocumentationbatch: 'fa-boxes',
        update: 'fa-edit'
      }

      return icons[action]
    },
    metaUnwantedFieldsHandler(meta) {
      if (meta == undefined) return { fields: [], id: '' }

      const aux = Object.assign({}, meta)
      aux.fields = meta.fields.filter(
        (x) =>
          x.key != 'id' &&
          x.key != 'details' &&
          x.key != 'actions' &&
          x.key != 'loadingStatus' &&
          x.key != 'mapInspectionStatus' &&
          x.key != 'mapFumigationStatus'
      )

      return aux
    },
    createEntity(endpoint, masterName, item, options) {
      return service.CreateItem(endpoint, masterName, item, options)
    },
    deleteEntity() {
      this.$brain.confirm(
        {
          title: this.$t('core.crud.attention'),
          subtitle: this.$tc('core.crud.alertRemoveMessage')
        },
        async () => {
          const { endpoint, reloadTable } = this.params.api.context
          const deleteService = service.createModalCrudService(endpoint)

          deleteService
            .delete(this.rowId)
            .then(() => {
              this.notify.success({
                title: this.$t(
                  'controlTower.pages.stuffingPortal.deleteSuccess'
                )
              })
              reloadTable()
            })
            .catch(() => {
              this.notify.error({
                title: this.$t('controlTower.pages.stuffingPortal.deleteError')
              })
            })
        }
      )
    },
    reloadTable() {
      const { reloadTable } = this.params.api.context
      if (reloadTable != undefined) {
        reloadTable()
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getComments(data) {
      const key = Object.keys(data).find((x) =>
        x.toLowerCase().includes('comments')
      )

      return key != undefined ? data[key] : []
    },
    definePermission(parentPermission) {
      if (parentPermission) {
        this.permission = parentPermission
        this.params.node.data.actionPermission = parentPermission
      } else {
        this.permission = this.params.node.data.actionPermission
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input--selection-controls {
  margin-top: 0px;
}
.radio-flex {
  display: flex;
}
.container-actions {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.column {
  flex-direction: column;
}
.container-documentation-actions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 15px;
}
.container-batch-actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-bottom: 15px;
}
.container-grid-actions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-bottom: 15px;
}
.cell {
  display: flex;
  align-items: center;
  height: 100%;
}
.status {
  border-radius: 50%;
  width: 15px;
  height: 15px;
}
.value {
  text-transform: uppercase;
}
.card-actions-container {
  gap: 10px;
}
#btn-send-comment {
  padding: 0 !important; //overrides v-btn 0 40px!
  min-width: 60px;
}
.message-container {
  color: #959db5;
  text-transform: uppercase;
}
.alert-container {
  max-width: 350px;
}

.timeline-items {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 20px;
}
/* start scrollbar */

::-webkit-scrollbar {
  width: 3px;
  height: 85px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #b9b9b9;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #27bee7;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #27bee7;
}
/* end scrollbar*/
</style>
