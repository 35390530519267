<template>
  <div class="renderer-wrapper">
    <bc-grid
      ref="gridComponent"
      :gridOptions="{
        pagination: false,
        getRowHeight,
        ...defaultGridOptions
      }"
      :columnDefs="columnDefsDetail"
      :getItems="getItems"
      :countItems="getDataCount"
      :style="`height: 100%; width: 100%`"
      :detailCellRendererFramework="frameworkCellRenderer"
      :detailCellRendererParams="{
        mainKeys,
        i18nTable,
        mainKeysLevel,
        parentLevelKey,
        keyLevel: this.currentLevel,
        metaKey,
        meta,
        layer: layer - 1,
        parentReloadTable: this.parentReloadTable
      }"
      :masterDetail="true"
      :selectable="false"
      :pageSize="13"
      :layer="layer - 1"
      :customSetSize="customSetSize"
      mode="serverSide"
      @grid-ready="onGridReady"
      @rowGroupOpened="onGroupOpened"
      @reload-table="reloadTable"
    />
  </div>
</template>

<script>
import { createCrudColumns, BcGrid } from '@brain/core'
import FrameworkCellRenderer from './FrameworkCellRenderer'
import StuffingPortalActionsCellRenderer from './StuffingPortalActionsCellRenderer'
import StuffingPortalStatusCellRenderer from './StuffingPortalStatusCellRenderer'
import RadioCellRenderer from './RadioCellRenderer.vue'
import service from '../../api/stuffingPortalService'
import Vue from 'vue'
import { metadataHelper, gridUtils } from '@cargill/shared'

export default {
  components: {
    BcGrid
  },
  data() {
    return {
      frameworkCellRenderer: null,
      columnDefsDetail: null,
      rowDataDetail: null,
      mainKeys: null,
      mainKeysLevel: null,
      currentLevel: null,
      keyLevel: null,
      parentLevelKey: null,
      meta: null,
      metaKey: null,
      i18nTable: null,
      groupDefaultExpanded: null,
      context: null,
      layer: 3,
      parentReloadTable: null,
      defaultGridOptions: gridUtils.defaultGridOptions
    }
  },
  beforeMount() {
    this.frameworkCellRenderer = Vue.extend(FrameworkCellRenderer)
    this.i18nTable = this.params.i18nTable
    this.layer = this.params.layer
    this.parentReloadTable = this.params.parentReloadTable
    this.mainKeys = this.params.mainKeys
    this.mainKeysLevel = this.params.mainKeysLevel // endpoints and parent grouping keys
    this.metaKey = `sub${this.capitalizeFirstLetter(this.params.metaKey)}`
    this.meta = this.params.meta
    this.currentLevel = this.defineLevel(this.params) // current level key
    this.parentLevelKey = this.createGroupKey(this.params) // associate parent grouping keys to its values

    const childMeta =
      this.params.meta[`sub${this.capitalizeFirstLetter(this.metaKey)}`]
    this.childLevel = metadataHelper.defineLevel(childMeta, this.mainKeysLevel)

    if (this.childLevel != -1) {
      this.context = this.createContext({
        parent: 'id',
        endpoint: this.mainKeysLevel[this.currentLevel].endpoint,
        childEndpoint: this.mainKeysLevel[this.childLevel].endpoint,
        masterName: this.mainKeysLevel[this.childLevel]?.masterName,
        childMeta: childMeta,
        meta: this.params.meta[this.metaKey],
        i18nTable: this.i18nTable,
        parentPermission: this.params.node.data.actionPermission
      })
    } else {
      this.context = this.createContext({
        parent: 'id',
        endpoint: this.mainKeysLevel[this.currentLevel].endpoint,
        childEndpoint: '',
        masterName: '',
        childMeta: childMeta,
        meta: this.params.meta[this.metaKey],
        i18nTable: this.i18nTable,
        parentPermission: this.params.node.data.actionPermission
      })
    }

    this.columnDefsDetail = this.createColumns(this.meta[this.metaKey])
    this.tableReady = true
  },
  mounted() {},
  methods: {
    customSetSize(aggridOptions) {
      if (aggridOptions) {
        gridUtils.resize(aggridOptions)
      }
    },
    translate(key) {
      return this.$t(`controlTower.pages.${this.i18nTable}.${key}`)
    },
    createColumns(metadata) {
      const crudColumns = createCrudColumns(
        metadata,
        'serverSide',
        this.translate
      ).filter((x) => x.key != 'id' && x.key != 'details')
      metadataHelper.defineCellRendererStuffingPortal(
        crudColumns,
        RadioCellRenderer,
        StuffingPortalActionsCellRenderer,
        StuffingPortalStatusCellRenderer
      )
      return crudColumns
    },
    onGroupOpened(event) {
      this.keyLevel = event.data[this.currentLevel]
    },
    onGridReady(params) {
      this.context.reloadTable = this.reloadTable
      params.api.context = Object.assign(params.api.context, this.context)
    },
    createGroupKey({ data, mainKeysLevel, parentLevelKey }) {
      const { parentData } = mainKeysLevel[this.currentLevel]
      const obj = {}

      parentData.forEach((key) => {
        obj[key] = data[key]
      })

      return { ...parentLevelKey, ...obj }
    },
    defineLevel({ mainKeysLevel, meta }) {
      return metadataHelper.defineLevel(meta[this.metaKey], mainKeysLevel)
    },
    getRowHeight(params) {
      var isDetailRow = params.node.detail

      // for all rows that are not detail rows, return nothing
      if (!isDetailRow) {
        return undefined
      }

      // otherwise return height based on number of rows in detail grid
      //var detailPanelHeight = params.data.details.length * 150
      return 200
    },
    getDataCount(filter) {
      const { endpoint } = this.mainKeysLevel[this.currentLevel]
      return service.getItemsChildCount(filter, endpoint, this.parentLevelKey)
    },
    getItems(params) {
      const { endpoint } = this.mainKeysLevel[this.currentLevel]
      return service.getItemsChild(params, endpoint, this.parentLevelKey)
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    reloadTable() {
      const bcGridRef = this.$refs.gridComponent
      bcGridRef.loadData()
      this.parentReloadTable()
    },
    createContext({
      parent,
      endpoint,
      childMeta,
      childEndpoint,
      masterName,
      meta,
      i18nTable,
      parentPermission
    }) {
      return {
        parent: parent,
        endpoint: endpoint,
        childMeta: childMeta,
        childEndpoint: childEndpoint,
        masterName: masterName,
        meta: meta,
        i18nTable: i18nTable,
        parentPermission: parentPermission
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.renderer-wrapper {
  height: 100%;
  padding: 10px 5px;
  margin: 5px 0 0 40px;
}
</style>
