<template>
  <div class="table-container">
    <div class="table-container_label_wrapper">
      <div class="table-container_label">
        <div
          class="table-container_icon"
          :style="{ backgroundColor: color }"
        ></div>
        <div class="table-container_description">{{ $t(title) }}</div>
      </div>

      <!-- <div class="table-container_filter">
        <div class="table-container_filter_inline">
          {{ $t('controlTower.pages.stuffingPortal.period') }}:
          <span v-show="displayRange.length" style="font-weight: normal">
            {{ $t('application.misc.period') }}:
            {{
              filterRange.length
                ? `${displayRange[0]} ${$t('application.misc.till')} ${
                    displayRange[1]
                  } `
                : ''
            }}
          </span>
        </div>

        <bc-popover v-model="menu" placement="bottom-end" :min-width="400">
          <template v-slot:activator="{ on, attrs }">
            <bc-btn
              icon
              class="filter-icon"
              v-bind="attrs"
              v-on="on"
              :max-width="30"
              :max-height="30"
              :title="$t('controlTower.pages.forecastSales.filter')"
            >
              <bc-icon>fas fa-filter</bc-icon>
            </bc-btn>
          </template>

          <template v-slot:header>
            <div>{{ $t('controlTower.pages.forecastSales.filter') }}</div>
          </template>

          <div id="global-filter-content">
            <datepicker-range
              v-model="filterRange"
              :maxDate="maxDate"
              :minDate="minDate"
              @input="validateRange()"
              attach="#global-filter-content"
            />

            <bc-divider></bc-divider>

            <bc-card-actions>
              <bc-spacer></bc-spacer>
              <bc-btn @click="menu = false" color="link-gray">
                {{ $t('application.actions.cancel') }}
              </bc-btn>
              <bc-btn @click="saveFilter" color="primary">
                {{ $t('application.actions.save') }}
              </bc-btn>
            </bc-card-actions>
          </div>
        </bc-popover>
      </div> -->
    </div>
  </div>
</template>
<script>
import DatepickerRange from '../../components/date-picker/DatepickerRange'
import moment from 'moment'

const start = moment().startOf('year').format('YYYY-MM-DD')
const end = moment().endOf('year').format('YYYY-MM-DD')

const defaultFilter = {
  startDate: start,
  endDate: end
}

export default {
  name: 'StuffingPortalTabHeader',
  components: {
    DatepickerRange
  },
  props: {
    title: {},
    color: {
      type: String,
      default: '#F7C624'
    },
    defaultFilter: {
      type: Object,
      default: () => defaultFilter
    }
  },
  data() {
    return {
      menu: false,
      maxDate: null,
      minDate: null,
      filterRange: [start, end],
      displayRange: [],
      filter: this.defaultFilter
    }
  },
  async mounted() {
    this.setDisplayRange()
  },
  methods: {
    saveFilter() {
      this.setDisplayRange()
      this.filter = {
        startDate: this.filterRange[0],
        endDate: this.filterRange[1]
      }
      this.$emit('onFilterApplied', this.filter)
    },
    setDisplayRange() {
      this.displayRange = [
        this.dateFormat(this.filterRange[0], this.$t('application.dateFormat')),
        this.dateFormat(this.filterRange[1], this.$t('application.dateFormat'))
      ]
    },
    dateFormat(date, format) {
      return date == null ? '' : moment(date).format(format)
    },
    validateRange() {
      if (this.filterRange.length == 1) {
        this.maxDate = moment(this.filterRange[0])
          .add(12, 'month')
          .format('YYYY-MM-DD')

        this.minDate = this.dateFormat(this.filterRange[0], 'YYYY-MM-DD')
      }
      if (this.filterRange.length == 2) {
        this.filter = false
        this.minDate = null
        this.maxDate = null
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table-container {
  &_label {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &_description {
    color: #959db5;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75vw;
  }
  &_icon {
    width: 15px;
    height: 15px;
    border-radius: 20%;
    background-color: red;
  }
  &_filter {
    display: flex;
    color: #959db5;
    font-size: 0.75vw;
    font-weight: bold;
    text-transform: uppercase;
    align-items: center;

    i {
      color: #959db5 !important;
      font-size: 1rem !important;
      cursor: pointer;
    }
  }
}
</style>
