<template>
  <div class="stuffing-portal-wrapper">
    <div class="stuffing-portal-label">
      <div>{{ $t('controlTower.pages.stuffingPortal.title') }}</div>
      <div class="import-export-container">
        <div v-if="currentTab == 1 || currentTab == 3 || currentTab == 4">
          <bc-btn
            color="link-gray"
            @click="doTransferContainers"
            :title="$t('controlTower.pages.stuffingPortal.transferContainers')"
            id="btn-activator-transfer-containers"
          >
            {{ $t('controlTower.pages.stuffingPortal.transferContainers') }}
          </bc-btn>
        </div>
        <div>
          <bc-btn
            color="link-gray"
            @click="doImport"
            :title="importDataTitle"
            id="btn-activator-import"
          >
            {{ importDataTitle }}
          </bc-btn>
        </div>
        <div>
          <bc-btn
            color="link-gray"
            @click="doExport"
            :title="$t('controlTower.pages.stuffingPortal.export')"
            id="btn-activator-export"
          >
            {{ $t('controlTower.pages.stuffingPortal.export') }}
          </bc-btn>
        </div>
      </div>
    </div>
    <div class="tabs-wrapper" v-if="tabsReady">
      <!-- Tabs -->
      <bc-tabs v-model="tab">
        <v-tabs-slider></v-tabs-slider>

        <bc-tab
          v-for="(item, index) in filteredItems"
          :key="item.title"
          class="tab-width"
          @change="loadTab(index)"
        >
          <div class="tabs-container">
            <div
              class="tab-icon"
              :style="{ backgroundColor: item.tabColor }"
            ></div>
            {{ $t(item.title) }}
          </div>
        </bc-tab>
      </bc-tabs>

      <!-- Table contents -->
      <bc-tabs-items v-model="tab">
        <bc-tab-item v-for="(item, index) in filteredItems" :key="item.title">
          <!-- <v-sheet v-if="!dataLoaded">
              <v-skeleton-loader
                class="mx-auto cargill"
                type="table"
                color="#2B323F"
              ></v-skeleton-loader>
            </v-sheet> -->

          <div>
            <stuffing-portal-tab-header
              ref="stuffingPortalTabHeader"
              :title="item.title"
              :color="item.tabColor"
            ></stuffing-portal-tab-header>
            <div v-if="isImportingTransferContainers">
              <cg-crud-import-custom
                :name="translate(`stuffingPortal.transferOfContainers`)"
                :label="translate(`stuffingPortal.transferOfContainers`)"
                :service="service"
                :translate="translate"
                :tryTranslateOtherLocale="translate"
                @close="closeImportTransferContainers"
              />
            </div>
            <div v-else-if="isListMode()">
              <stuffing-portal-table
                ref="stuffingPortalTable"
                :tabIndex="index"
                :mainKeys="item.mainKeys"
                :mainKeysLevel="item.mainKeysLevel"
                :endpoint="item.endpoint"
                :i18nTable="item.i18nTable"
                @onFilterApplied="item.lastFilterApplied = $event"
              ></stuffing-portal-table>
            </div>
            <div v-else-if="isImportMode()">
              <bc-crud-import
                ref="stuffingPortalImport"
                :tabIndex="index"
                :name="item.i18nTable"
                :label="translate(`${item.i18nTable}.title`)"
                :service="item.service"
                :translate="translate"
                :tryTranslateOtherLocale="translate"
                @close="navigateToList"
              />
            </div>
          </div>
        </bc-tab-item>
      </bc-tabs-items>
    </div>
  </div>
</template>
<script>
import StuffingPortalTable from '../components/stuffing-portal-table/StuffingPortalTable'
import StuffingPortalTabHeader from '../components/stuffing-portal-table/StuffingPortalTabHeader'
import service from '../api/stuffingPortalService'
import {
  CgCrudImportCustom,
  eventHub,
  useUserSettings,
  useModuleOpen
} from '@cargill/shared'
import { BcCrudImport } from '@brain/core'
import { mapState } from 'pinia'
import stuffingPortalService from '../api/stuffingPortalService'
import FileDownload from 'js-file-download'

const { hasTransaction } = useModuleOpen()

export default {
  name: 'StuffingPortal',
  components: {
    BcCrudImport,
    CgCrudImportCustom,
    StuffingPortalTable,
    StuffingPortalTabHeader
  },
  data() {
    return {
      tabsReady: true,
      translatePrefix: 'controlTower.pages',
      mode: 'List',
      name: '',
      label: '',
      service: service,
      tab: 0,
      isImportingTransferContainers: false,
      items: [
        {
          endpoint: 'StuffingPortalReserve/getStuffingPortalReserve',
          mainKeys: ['registryDate'],
          mainKeysLevel: {
            registryDate: {
              endpoint: 'StuffingPortalReserve/getStuffingPortalReserve',
              parentData: []
            }
          },
          title: 'controlTower.pages.stuffingPortal.reserve',
          i18nTable: 'stuffingPortal.reserveTable',
          service: stuffingPortalService.createService('stuffingPortalReserve'),
          tabColor: '#f7c624',
          name: 'reserve'
        },
        {
          endpoint:
            'StuffingPortalContainersWithdrawal/getStuffingPortalContainersWithdrawal',
          mainKeys: ['registryDate'],
          mainKeysLevel: {
            registryDate: {
              endpoint:
                'StuffingPortalContainersWithdrawal/getStuffingPortalContainersWithdrawal',
              parentData: []
            },
            containerCode: {
              endpoint:
                'StuffingPortalContainersWithdrawalContainer/getStuffingPortalContainersWithdrawalContainer',
              parentData: ['id'],
              masterName: 'StuffingPortalContainersWithdrawalId'
            }
          },
          title: 'controlTower.pages.stuffingPortal.containersWithdrawal',
          i18nTable: 'stuffingPortal.containersWithdrawalTable',
          service: stuffingPortalService.createService(
            'stuffingPortalContainersWithdrawal'
          ),
          tabColor: '#505bf0',
          name: 'containersWithdrawal'
        },
        {
          endpoint: 'StuffingPortalUnloading/getStuffingPortalUnloading',
          mainKeys: ['registryDate', 'purchaseContractReference'],
          mainKeysLevel: {
            registryDate: {
              endpoint: 'StuffingPortalUnloading/getStuffingPortalUnloading',
              parentData: []
            },
            purchaseContractReference: {
              endpoint:
                'StuffingPortalUnloadingDriver/getStuffingPortalUnloadingDriver',
              parentData: ['id'],
              masterName: 'StuffingPortalUnloadingId'
            }
          },
          title: 'controlTower.pages.stuffingPortal.unload',
          i18nTable: 'stuffingPortal.unloadTable',
          service: stuffingPortalService.createService(
            'stuffingPortalUnloading'
          ),
          tabColor: '#a32f80'
        },
        {
          endpoint: 'StuffingPortalLoading/getStuffingPortalLoading',
          mainKeys: ['registryDate', 'containerCode'],
          mainKeysLevel: {
            registryDate: {
              endpoint: 'StuffingPortalLoading/getStuffingPortalLoading',
              parentData: []
            },
            containerCode: {
              endpoint:
                'StuffingPortalLoadingContainer/getStuffingPortalLoadingContainer',
              parentData: ['id'],
              masterName: 'StuffingPortalLoadingId'
            }
          },
          title: 'controlTower.pages.stuffingPortal.boarding',
          i18nTable: 'stuffingPortal.boardingTable',
          service: stuffingPortalService.createService('stuffingPortalLoading'),
          tabColor: '#fe6363'
        },
        {
          endpoint:
            'StuffingPortalDocumentation/getStuffingPortalDocumentation',
          mainKeys: ['registryDate', 'container', 'batch'],
          mainKeysLevel: {
            registryDate: {
              endpoint:
                'StuffingPortalDocumentation/getStuffingPortalDocumentation',
              parentData: []
            },
            container: {
              endpoint:
                'StuffingPortalDocumentationRequest/getStuffingPortalDocumentationRequest',
              parentData: ['id']
            },
            batch: {
              endpoint:
                'StuffingPortalDocumentationBatch/getStuffingPortalDocumentationBatch',
              parentData: ['id'],
              masterName: 'StuffingPortalDocumentationRequestId'
            }
          },
          title: 'controlTower.pages.stuffingPortal.documentation',
          i18nTable: 'stuffingPortal.documentationTable',
          service: stuffingPortalService.createService(
            'stuffingPortalDocumentation'
          ),
          tabColor: '#ffffff'
        },
        {
          transaction: 'logisticPortalExportControl',
          endpoint:
            'logisticPortalExportControl/getLogisticPortalExportControl',
          mainKeys: ['registryDate'],
          mainKeysLevel: {
            registryDate: {
              endpoint:
                'logisticPortalExportControl/getLogisticPortalExportControl',
              parentData: []
            }
          },
          title: 'controlTower.pages.stuffingPortal.control',
          i18nTable: 'stuffingPortal.controlTable',
          service: stuffingPortalService.createService(
            'logisticPortalExportControl'
          ),
          tabColor: '#92D050'
        }
      ],
      dataLoaded: false,
      currentTab: 0
    }
  },
  beforeMount() {},
  beforeDestroy() {
    eventHub.$off('add-notification')
    eventHub.$off('filter-table')
  },
  mounted() {
    eventHub.$on('add-notification', (_notification) => {
      this.notification(_notification.type, _notification.message)
    })
  },
  computed: {
    ...mapState(useUserSettings, {
      selectedLanguage: (state) => state.getLanguage()
    }),
    importDataTitle() {
      return this.isImportMode()
        ? this.$t('controlTower.pages.stuffingPortal.viewData')
        : this.$t('controlTower.pages.stuffingPortal.import')
    },
    filteredItems() {
      return this.items.filter(
        (x) => x.transaction == null || hasTransaction(x)
      )
    }
  },
  methods: {
    notification(_type, _message) {
      if (this.notify) {
        switch (_type) {
          case 'success':
            this.notify.success({ title: _message })
            break
          case 'error':
            this.notify.error({ title: _message })
        }
      }
    },
    getStuffingPortalTableTab(index) {
      return this.$refs.stuffingPortalTable.find((x) => x.tabIndex == index)
    },
    reloadCurrentTab() {
      const stuffingPortalTable = this.getStuffingPortalTableTab(
        this.currentTab
      )
      stuffingPortalTable?.reloadTable()
    },
    loadTab(index) {
      const stuffingPortalTable = this.getStuffingPortalTableTab(index)
      stuffingPortalTable?.loadTable()
      this.$route.params.name = this.filteredItems[index].i18nTable
      this.currentTab = index
    },
    getCurrentItem() {
      return this.filteredItems[this.currentTab]
    },
    async reRenderAllTabs() {
      this.tabsReady = false
      setTimeout(() => (this.tabsReady = true), 300)
    },
    navigateToList() {
      this.mode = 'List'
    },
    closeImportTransferContainers() {
      this.isImportingTransferContainers = false
    },
    translate(key, locale) {
      const translateKey = this.translatePrefix
        ? `${this.translatePrefix}.${key}`
        : key
      return this.$t(translateKey, locale)
    },
    doImport() {
      this.$route.params.name = this.getCurrentItem().i18nTable
      this.mode = this.isImportMode() ? 'List' : 'Import'
      this.closeImportTransferContainers()
    },
    doTransferContainers() {
      this.$route.params.name = this.getCurrentItem().i18nTable
      this.mode = this.isImportMode() ? 'List' : 'Import'
      this.isImportingTransferContainers = true
    },
    isImportMode() {
      return this.mode == 'Import'
    },
    isListMode() {
      return this.mode == 'List'
    },
    async doExport() {
      const currentItem = this.getCurrentItem()
      const filter = currentItem.lastFilterApplied
      const result = await currentItem.service.export(filter?.grid)
      const label = this.translate(`${currentItem.i18nTable}.title`)
      FileDownload(result.data, result.filename ?? `${label}.xlsx`)
    }
  },
  watch: {
    selectedLanguage() {
      this.reRenderAllTabs()
    }
  }
}
</script>
<style lang="scss" scoped>
.stuffing-portal-wrapper {
  margin: 1rem;
  background-color: #232834;
  .stuffing-portal-label {
    color: var(--bc-title-color);
    font-family: var(--bc-font-regular);
    font-weight: 900;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-content: center;
    background-color: #232834;
    margin-top: 1.3rem;
    align-items: center;
    margin: 0 1rem;
  }
  .import-export-container {
    display: flex;
    justify-content: space-between;
  }

  .tabs-wrapper {
    padding: 1rem;
    .tabs-container {
      display: flex;
      grid-gap: 10px;
      white-space: nowrap;
      .tab-icon {
        width: 15px;
        height: 15px;
        border-radius: 20%;
      }
    }
  }
}
::v-deep {
  .v-tabs {
    background-color: #262c39 !important;
  }
  .v-tabs-items {
    padding: 12px 16px !important;
  }
  .tab-width {
    width: min-content !important;
  }
}
.uppercase {
  text-transform: uppercase;
}
#btn-activator-transfer-containers,
#btn-activator-export,
#btn-activator-import {
  padding: 0 !important; //overrides v-btn 0 40px!
  margin-left: 15px;
}
</style>
