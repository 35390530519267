<script>
import { BcGrid, helpers } from '@brain/core'

export default {
  name: 'CargillBcGrid',
  extends: BcGrid,
  methods: {
    createServerSideDataSource(getItems, countItems, serviceParams) {
      return {
        getRows: async (agGridParams) => {
          this.aggridApi.showLoadingOverlay()
          this.isEmpty = true
          try {
            const queryParamsObject = helpers.buildQueryObject(
              agGridParams.request,
              serviceParams
            )
            const queryStringParams =
              helpers.buildQueryParams(queryParamsObject)
            const data = await getItems(queryStringParams)
            const count = await countItems(
              helpers.buildQueryParams({
                filterQuery: queryParamsObject.filterQuery
              })
            )

            if (Array.isArray(data) && data.length > 0) {
              agGridParams.successCallback(data, count)
              this.aggridApi.hideOverlay()
              this.isEmpty = false
              this.setSize()
            } else {
              this.aggridApi.showNoRowsOverlay()
            }
            this.$emit('afterDataLoaded')
          } catch (e) {
            console.error(e)
            this.aggridApi.showNoRowsOverlay()
            this.$emit('afterDataLoaded')
          }
        }
      }
    }
  }
}
</script>
